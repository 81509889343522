<template>
  <v-layout row class="admin-drawer">
    <v-navigation-drawer app permanent class="navigation">
      <v-list dense>
        <v-list-tile
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          exact
        >
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-layout>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "Admin",
  data() {
    return {
      items: [
        { title: "System", icon: "description", to: "/admin/system" },
        { title: "Organizations", icon: "people", to: "/admin/orgs" },
        { title: "Twitch", icon: "games", to: "/admin/twitch" },
        { title: "Hosts", icon: "contacts", to: "/admin/hosts" },
        { title: "Search", icon: "description", to: "/admin/scripts" }
      ]
    }
  }
})
</script>

<style lang="scss">
.admin-drawer {
  .navigation {
    margin-top: 35px !important;
  }
}
</style>
